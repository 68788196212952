<template>
  <div class="formula_query_builder">
    <div class="tools"></div>
    <div ref="editor" class="editor" :placeholder="placeholder" :style="editorStyled">
      <chips-index v-model="model"></chips-index>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ChipsIndex from './Chips/ChipsIndex'

import VueContext from 'vue-context'
import 'vue-context/src/sass/vue-context.scss'

import defaultFunctions from './utils/functions'
import defaultOperands from './utils/operands'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTextHeight } from '@fortawesome/free-solid-svg-icons'

import deepClone from './utils/utilities.js'
library.add(faTextHeight)

Vue.component('chips-index', ChipsIndex)

export default {
  name: 'FormulaQueryBuilder',

  props: {
    value: Object,

    width: {
      type: Number,
      default: 0
    },

    height: {
      type: Number,
      default: 300
    },

    placeholder: {
      type: String,
      default: 'Calculator'
    },

    functions: {
      type: Array,
      default () {
        return defaultFunctions
      }
    },

    operands: {
      type: Array,
      default () {
        return defaultOperands
      }
    },

    toolSize: {
      type: String,
      default: 'small'
    }
  },

  computed: {
    editorStyled () {
      let styled = {
        height: `${this.height}px`
      }

      if (this.width !== 0) {
        styled.width = `${this.width}px`
      }

      return styled
    }
  },

  data () {
    return {
      model: {
        type: 'index',
        children: []
      }
    }
  },

  mounted () {
    this.$watch(
      'model',
      newModel => {
        if (JSON.stringify(newModel) !== JSON.stringify(this.value)) {
          this.$emit('input', deepClone(newModel))
        }
      }, {
        deep: true
      })

    this.$watch(
      'value',
      newValue => {
        if (JSON.stringify(newValue) !== JSON.stringify(this.model)) {
          this.model = deepClone(newValue)
        }
      }, {
        deep: true
      })

    if (typeof this.$options.propsData.value !== 'undefined') {
      this.model = Object.assign(this.model, this.$options.propsData.value)
    }
  },

  methods: {
  }
}
</script>

<style lang="scss">
@import "./FormulaQueryBuilder.scss";
</style>
